import {
	CommunicationPreferences,
	GetSessionResponse
} from '@agentero/service-clients/portal/session';

import { AgentAgency, parseAgentAgency } from './agent/AgentAgency';
import { AgentCompliance, parseAgentCompliance } from './agent/AgentCompliance';
import { AgentSubscription, parseAgentSubscriptionConnect } from './agent/AgentSubscription';
import { Role, parseRole } from './agent/Role';

export type Agent = {
	id: string;
	token: string;
	email: string;
	firstName: string;
	lastName: string;
	photoUrl: string;
	isImpersonation: boolean;
	role: Role;
	isContractAccepted: boolean;
	agency: AgentAgency;
	phoneNumber: string;
	isCommercialRaterEnabled: boolean;
	appointmentsEnabled: boolean;
	compliance: AgentCompliance;
	subscription?: AgentSubscription;
	communicationsPreferences?: {
		email?: boolean;
		sms?: boolean;
	};
	hasAppointedTerminated: boolean;
	isPilotAgency: boolean;
};

export const parseAgent = (agentSession: GetSessionResponse): Agent => {
	const role = parseRole(agentSession.role);

	return {
		id: agentSession.id,
		token: agentSession.token,
		email: agentSession.email,
		firstName: agentSession.firstName,
		lastName: agentSession.lastName,
		photoUrl: agentSession.photoUrl ?? '',
		isImpersonation: agentSession.isImpersonated || false,
		role,
		isContractAccepted: role === Role.SuperAdmin ? true : agentSession.isContractAccepted || false,
		agency: parseAgentAgency(agentSession),
		phoneNumber: agentSession.phoneNumber,
		isCommercialRaterEnabled: Boolean(agentSession?.hasCommercialRater),
		appointmentsEnabled: Boolean(agentSession?.appointmentsEnabled),
		compliance: parseAgentCompliance(agentSession),
		subscription: parseAgentSubscriptionConnect(agentSession.currentTier!),
		communicationsPreferences:
			role === Role.SuperAdmin
				? undefined
				: parseCommunications(agentSession.communicationPreferences),
		hasAppointedTerminated: !!agentSession?.hasTerminatedAppointments,
		isPilotAgency: agentSession.isPilotAgency
	};
};

const parseCommunications = (communicationPreferences: CommunicationPreferences | undefined) => ({
	email: communicationPreferences?.email ?? undefined,
	sms: communicationPreferences?.sms ?? undefined
});

export const isAgent = (agent: Agent) =>
	agent && !agent.isImpersonation && agent.role !== Role.SuperAdmin;
