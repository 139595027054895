// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts,import_extension=none"
// @generated from file agentero/portal/vehicle.proto (package agentero.portal, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetMakesRequest, GetMakesResponse, GetModelsRequest, GetModelsResponse, GetVINRequest, GetVINResponse } from "./vehicle_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service agentero.portal.VehicleService
 */
export const VehicleService = {
  typeName: "agentero.portal.VehicleService",
  methods: {
    /**
     * @generated from rpc agentero.portal.VehicleService.GetMakes
     */
    getMakes: {
      name: "GetMakes",
      I: GetMakesRequest,
      O: GetMakesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc agentero.portal.VehicleService.GetModels
     */
    getModels: {
      name: "GetModels",
      I: GetModelsRequest,
      O: GetModelsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc agentero.portal.VehicleService.GetVIN
     */
    getVIN: {
      name: "GetVIN",
      I: GetVINRequest,
      O: GetVINResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

