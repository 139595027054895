import { Carrier as CarrierProto } from '@agentero/grpc-clients/grpc/shared/carrier';
import {
	HiscoxSlideshow,
	IconAMIG,
	IconAgentero,
	IconAmtrust,
	IconAspire,
	IconAssuranceAmerica,
	IconBamboo,
	IconBambooSlideshow,
	IconBiBerk,
	IconBoundlessRider,
	IconBoundlessRiderSlideshow,
	IconBranch,
	IconBristolWest,
	IconCNA,
	IconChubb,
	IconClearcover,
	IconCommonwealth,
	IconCoterie,
	IconCowbell,
	IconDairyland,
	IconDelos,
	IconElephant,
	IconEmployers,
	IconForemostSignature,
	IconGainsco,
	IconGreatAmerican,
	IconHanover,
	IconHippo,
	IconKemper,
	IconLamar,
	IconLamarSlideshow,
	IconLemonade,
	IconLio,
	IconLonestar,
	IconNationwide,
	IconNext,
	IconOpenly,
	IconOpenlySlideshow,
	IconPie,
	IconPieSlideshow,
	IconProgressive,
	IconRainbow,
	IconRoot,
	IconRootSlideshow,
	IconSafeco,
	IconSes,
	IconStillwater,
	IconStonegate,
	IconTheGeneral,
	IconTravelers,
	IconUnique,
	IconWestfield,
	KemperSlideshow,
	LightspeedSlideshow,
	MGTSlideshow,
	PlymouthRockSlideshow,
	ThreeSlideshow
} from '@agentero/icons';

import { RaterCarrier, RaterCarrierEnum } from './carrier/carrierRaterConfig';

export enum Carrier {
	Undefined = 'undefined',
	Hippo = 'hippo',
	Clearcover = 'clearcover',
	HavenLife = 'haven_life',
	Openly = 'openly',
	AonEdge = 'aon_edge',
	GetCovered = 'get_covered',
	Cover = 'cover',
	PieInsurance = 'pie_insurance',
	Coterie = 'coterie',
	Branch = 'branch',
	Elephant = 'elephant',
	Mileauto = 'mileauto',
	Pouch = 'pouch',
	Ethos = 'ethos',
	Lemonade = 'lemonade',
	Thimble = 'thimble',
	Cowbell = 'cowbell',
	Stillwater = 'stillwater',
	Bamboo = 'bamboo',
	StateAuto = 'state_auto',
	Sensa = 'sensa',
	Ladder = 'ladder',
	Next = 'next',
	Gainsco = 'gainsco',
	Travelers = 'travelers',
	HomeownersOfAmerica = 'homeowners_of_america',
	CNA = 'cna',
	Obie = 'obie',
	Safeco = 'safeco',
	Progressive = 'progressive',
	Lamar = 'lamar',
	Biberk = 'biberk',
	Nationwide = 'nationwide',
	Pathpoint = 'pathpoint',
	Hiscox = 'hiscox',
	Amtrust = 'amtrust',
	GreatAmerican = 'great_american',
	AMIG = 'amig',
	Foremost = 'foremost',
	BristolWest = 'bristol_west',
	Westfield = 'westfield',
	Lonestar = 'lonestar',
	Dairyland = 'dairyland',
	Delos = 'delos',
	TravelersCommercial = 'travelers_commercial',
	Employers = 'employers',
	Steadily = 'steadily',
	AssuranceAmerica = 'assurance_america',
	Commonwealth = 'commonwealth',
	Wellington = 'wellington',
	Lio = 'lio',
	Swyfft = 'swyfft',
	Neptune = 'neptune',
	Orion180 = 'orion180',
	Stonegate = 'stonegate',
	Unique = 'unique',
	TheGeneral = 'thegeneral',
	ForemostSignature = 'foremostsignature',
	Rainbow = 'rainbow',
	Aspire = 'aspire',
	Chubb = 'chubb',
	ChubbPersonal = 'chubb_personal',
	Hanover = 'hanover',
	Ses = 'ses',
	BoundlessRider = 'boundless_rider',
	Root = 'root',
	Kemper = 'kemper',
	KemperCommercial = 'kemper_commercial',
	Three = 'three',
	Lightspeed = 'lightspeed',
	MGT = 'mgt',
	PlymouthRock = 'plymouth_rock'
}

export enum CarrierType {
	AgenteroCarrier,
	NonAgenteroCarrier
}

type CarrierEntity = {
	type: CarrierType;
	carrier: Carrier;
	carrierProto: CarrierProto;
	name: string;
	isEnabled: () => boolean;
	raterCarrier: RaterCarrier;
	removeFromAppetite?: boolean;
	logo: string;
	label: string;
	slideshow: {
		color: string;
		icon: unknown;
	};
};

type AgenteroCarrier = CarrierEntity & {
	type: CarrierType.AgenteroCarrier;
	url: string;
	landingUrl: string;
};

type NonAgenteroCarrier = CarrierEntity & {
	type: CarrierType.NonAgenteroCarrier;
};

type CarrierConfiguration = AgenteroCarrier | NonAgenteroCarrier;

export const carrierConfigurations: { [key in Carrier]: CarrierConfiguration } = {
	[Carrier.Undefined]: {
		type: CarrierType.NonAgenteroCarrier,
		carrier: Carrier.Undefined,
		carrierProto: CarrierProto.UNDEFINED,
		name: 'undefined',
		logo: 'undefined-logo.svg',
		isEnabled: () => true,
		raterCarrier: RaterCarrierEnum.NonRaterCarrier,
		label: '',
		slideshow: {
			icon: null,
			color: '#000'
		}
	},
	[Carrier.Hippo]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Hippo,
		carrierProto: CarrierProto.HIPPO,
		name: 'hippo',
		url: 'hippo',
		landingUrl: 'hippo',
		isEnabled: () => false,
		logo: 'hippo-logo.svg',
		raterCarrier: {
			icon: IconHippo
		},
		label: 'Hippo',
		slideshow: {
			icon: IconHippo,
			color: '#009B1A'
		}
	},
	[Carrier.Clearcover]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Clearcover,
		carrierProto: CarrierProto.CLEAR_COVER,
		name: 'clearcover',
		url: 'clearcover',
		landingUrl: 'clearcover',
		isEnabled: () => true,
		logo: 'clearcover-logo.svg',
		raterCarrier: {
			icon: IconClearcover
		},
		label: 'Clearcover',
		slideshow: {
			icon: IconClearcover,
			color: '#4024D3'
		}
	},
	[Carrier.HavenLife]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.HavenLife,
		carrierProto: CarrierProto.HAVEN_LIFE,
		name: 'havenLife',
		url: 'haven-life',
		landingUrl: 'havenlife',
		isEnabled: () => true,
		logo: 'haven-life-logo.svg',
		raterCarrier: RaterCarrierEnum.NonRaterCarrier,
		removeFromAppetite: true,
		label: 'Haven Life',
		slideshow: {
			icon: IconAgentero,
			color: '#2DBC90'
		}
	},
	[Carrier.Openly]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Openly,
		carrierProto: CarrierProto.OPENLY,
		name: 'openly',
		url: 'openly',
		landingUrl: 'openly',
		isEnabled: () => true,
		logo: 'openly-logo.svg',
		raterCarrier: {
			icon: IconOpenly
		},
		label: 'Openly',
		slideshow: {
			icon: IconOpenlySlideshow,
			color: '#815F99'
		}
	},
	[Carrier.AonEdge]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.AonEdge,
		carrierProto: CarrierProto.AON_EDGE,
		name: 'aonEdge',
		url: 'aon-edge',
		landingUrl: 'aonedge',
		isEnabled: () => true,
		logo: 'aonedge-logo.png',
		raterCarrier: RaterCarrierEnum.NonRaterCarrier,
		label: 'Aon Edge',
		slideshow: {
			icon: IconAgentero,
			color: '#2DBC90'
		}
	},
	[Carrier.GetCovered]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.GetCovered,
		carrierProto: CarrierProto.GET_COVERED,
		name: 'getCovered',
		url: 'get-covered',
		landingUrl: 'getcovered',
		isEnabled: () => true,
		logo: 'get-covered-logo.svg',
		raterCarrier: RaterCarrierEnum.NonRaterCarrier,
		removeFromAppetite: true,
		label: 'Get Covered',
		slideshow: {
			icon: IconAgentero,
			color: '#2DBC90'
		}
	},
	[Carrier.Cover]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Cover,
		carrierProto: CarrierProto.COVER,
		name: 'cover',
		url: 'cover',
		landingUrl: 'cover',
		isEnabled: () => true,
		logo: 'cover-logo.svg',
		raterCarrier: RaterCarrierEnum.NonRaterCarrier,
		removeFromAppetite: true,
		label: 'Cover',
		slideshow: {
			icon: IconAgentero,
			color: '#2DBC90'
		}
	},
	[Carrier.PieInsurance]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.PieInsurance,
		carrierProto: CarrierProto.PIE,
		name: 'pieInsurance',
		url: 'pie-insurance',
		landingUrl: 'pie',
		isEnabled: () => true,
		logo: 'pie-insurance-logo.svg',
		raterCarrier: {
			icon: IconPie
		},
		label: 'Pie Insurance',
		slideshow: {
			icon: IconPieSlideshow,
			color: '#FF961A'
		}
	},
	[Carrier.Coterie]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Coterie,
		carrierProto: CarrierProto.COTERIE,
		name: 'coterie',
		url: 'coterie',
		landingUrl: 'coterie',
		isEnabled: () => true,
		logo: 'coterie-logo.svg',
		raterCarrier: {
			icon: IconCoterie
		},
		label: 'Coterie',
		slideshow: {
			icon: IconCoterie,
			color: '#4422FF'
		}
	},
	[Carrier.Branch]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Branch,
		carrierProto: CarrierProto.BRANCH,
		name: 'branch',
		url: 'branch',
		landingUrl: 'branch',
		isEnabled: () => true,
		logo: 'branch-logo.svg',
		raterCarrier: {
			icon: IconBranch
		},
		label: 'Branch',
		slideshow: {
			icon: IconBranch,
			color: '#527059'
		}
	},
	[Carrier.Elephant]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Elephant,
		carrierProto: CarrierProto.ELEPHANT,
		name: 'elephant',
		url: 'elephant',
		landingUrl: 'elephant',
		isEnabled: () => true,
		logo: 'elephant-logo.svg',
		raterCarrier: {
			icon: IconElephant
		},
		label: 'Elephant',
		slideshow: {
			icon: IconElephant,
			color: '#022459'
		}
	},
	[Carrier.Mileauto]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Mileauto,
		carrierProto: CarrierProto.MILE_AUTO,
		name: 'mileauto',
		url: 'mileauto',
		landingUrl: 'mileauto',
		isEnabled: () => true,
		logo: 'mileauto-logo.png',
		raterCarrier: RaterCarrierEnum.NonRaterCarrier,
		label: 'Mile Auto',
		slideshow: {
			icon: IconAgentero,
			color: '#2DBC90'
		}
	},
	[Carrier.Pouch]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Pouch,
		carrierProto: CarrierProto.POUCH,
		name: 'pouch',
		url: 'pouch',
		landingUrl: 'pouch',
		isEnabled: () => true,
		logo: 'pouch-logo.png',
		raterCarrier: RaterCarrierEnum.NonRaterCarrier,
		label: 'Pouch Insurance',
		slideshow: {
			icon: IconAgentero,
			color: '#2DBC90'
		}
	},
	[Carrier.Ethos]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Ethos,
		carrierProto: CarrierProto.ETHOS,
		name: 'ethos',
		url: 'ethos',
		landingUrl: 'ethos',
		isEnabled: () => true,
		logo: 'ethos-logo.png',
		raterCarrier: RaterCarrierEnum.NonRaterCarrier,
		label: 'Ethos',
		slideshow: {
			icon: IconAgentero,
			color: '#2DBC90'
		}
	},
	[Carrier.Lemonade]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Lemonade,
		carrierProto: CarrierProto.LEMONADE,
		name: 'lemonade',
		url: 'lemonade',
		landingUrl: 'lemonade',
		isEnabled: () => true,
		logo: 'lemonade-logo.svg',
		raterCarrier: {
			icon: IconLemonade
		},
		label: 'Lemonade',
		slideshow: {
			icon: IconLemonade,
			color: '#FF0083'
		}
	},
	[Carrier.Thimble]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Thimble,
		carrierProto: CarrierProto.THIMBLE,
		name: 'thimble',
		url: 'thimble',
		landingUrl: 'thimble',
		isEnabled: () => true,
		logo: 'thimble-logo.png',
		raterCarrier: RaterCarrierEnum.NonRaterCarrier,
		label: 'Thimble',
		slideshow: {
			icon: IconAgentero,
			color: '#2DBC90'
		}
	},
	[Carrier.Cowbell]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Cowbell,
		carrierProto: CarrierProto.COWBELL,
		name: 'cowbell',
		url: 'cowbell',
		landingUrl: 'cowbell',
		isEnabled: () => true,
		logo: 'cowbell-logo.png',
		raterCarrier: {
			icon: IconCowbell
		},
		label: 'Cowbell',
		slideshow: {
			icon: IconCowbell,
			color: '#2180E2'
		}
	},
	[Carrier.Stillwater]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Stillwater,
		carrierProto: CarrierProto.STILLWATER,
		name: 'stillwater',
		url: 'stillwater',
		landingUrl: 'stillwater',
		isEnabled: () => true,
		logo: 'stillwater-logo.png',
		raterCarrier: {
			icon: IconStillwater
		},
		label: 'Stillwater',
		slideshow: {
			icon: IconStillwater,
			color: '#26428A'
		}
	},
	[Carrier.Bamboo]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Bamboo,
		carrierProto: CarrierProto.BAMBOO,
		name: 'bamboo',
		url: 'bamboo',
		landingUrl: 'bamboo',
		isEnabled: () => true,
		logo: 'bamboo-logo.svg',
		raterCarrier: {
			icon: IconBamboo
		},
		label: 'Bamboo',
		slideshow: {
			icon: IconBambooSlideshow,
			color: '#e0552b'
		}
	},
	[Carrier.StateAuto]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.StateAuto,
		carrierProto: CarrierProto.STATE_AUTO,
		name: 'stateAuto',
		url: 'stateauto',
		landingUrl: 'stateauto',
		isEnabled: () => true,
		logo: 'stateauto-logo.png',
		raterCarrier: RaterCarrierEnum.NonRaterCarrier,
		removeFromAppetite: true,
		label: 'StateAuto',
		slideshow: {
			icon: IconAgentero,
			color: '#2DBC90'
		}
	},
	[Carrier.Sensa]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Sensa,
		carrierProto: CarrierProto.SENSA,
		name: 'sensa',
		url: 'sensa',
		landingUrl: 'sensa',
		isEnabled: () => true,
		logo: 'sensa-logo.svg',
		raterCarrier: RaterCarrierEnum.NonRaterCarrier,
		removeFromAppetite: true,
		label: 'Sensa',
		slideshow: {
			icon: IconAgentero,
			color: '#2DBC90'
		}
	},
	[Carrier.Ladder]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Ladder,
		carrierProto: CarrierProto.LADDER,
		name: 'ladder',
		url: 'ladder',
		landingUrl: 'ladder',
		isEnabled: () => true,
		logo: 'ladder-logo.svg',
		raterCarrier: RaterCarrierEnum.NonRaterCarrier,
		label: 'Ladder',
		slideshow: {
			icon: IconAgentero,
			color: '#2DBC90'
		}
	},
	[Carrier.Next]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Next,
		carrierProto: CarrierProto.NEXTCARRIER,
		name: 'next',
		url: 'next',
		landingUrl: 'next',
		isEnabled: () => true,
		logo: 'next-logo.svg',
		raterCarrier: RaterCarrierEnum.NonRaterCarrier,
		label: 'Next',
		slideshow: {
			icon: IconNext,
			color: '#231f20'
		}
	},
	[Carrier.Gainsco]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Gainsco,
		carrierProto: CarrierProto.GAINSCO,
		name: 'gainsco',
		url: 'gainsco',
		landingUrl: 'gainsco',
		isEnabled: () => true,
		logo: 'gainsco-logo.svg',
		raterCarrier: {
			icon: IconGainsco
		},
		label: 'Gainsco',
		slideshow: {
			icon: IconGainsco,
			color: '#C8332E'
		}
	},
	[Carrier.Travelers]: {
		type: CarrierType.NonAgenteroCarrier,
		carrier: Carrier.Travelers,
		carrierProto: CarrierProto.TRAVELERS,
		name: 'travelers',
		isEnabled: () => true,
		logo: 'travelers-logo.png',
		raterCarrier: {
			icon: IconTravelers
		},
		label: 'Travelers',
		slideshow: {
			icon: IconTravelers,
			color: '#E01719'
		}
	},
	[Carrier.HomeownersOfAmerica]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.HomeownersOfAmerica,
		carrierProto: CarrierProto.HOAIC,
		name: 'homeownersOfAmerica',
		url: 'homeowners-of-america',
		landingUrl: 'hoaic',
		isEnabled: () => true,
		logo: 'homeowners-of-america-logo.png',
		raterCarrier: RaterCarrierEnum.NonRaterCarrier,
		label: 'Homeowners of America',
		slideshow: {
			icon: IconAgentero,
			color: '#2DBC90'
		}
	},
	[Carrier.CNA]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.CNA,
		carrierProto: CarrierProto.CNA,
		name: 'cna',
		url: 'cna',
		landingUrl: 'cna',
		isEnabled: () => true,
		logo: 'cna-logo.svg',
		raterCarrier: {
			icon: IconCNA
		},
		label: 'CNA',
		slideshow: {
			icon: IconCNA,
			color: '#ED282C'
		}
	},
	[Carrier.Obie]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Obie,
		carrierProto: CarrierProto.OBIE,
		name: 'obie',
		url: 'obie',
		landingUrl: 'obie',
		isEnabled: () => true,
		logo: 'obie-logo.svg',
		raterCarrier: RaterCarrierEnum.NonRaterCarrier,
		label: 'Obie',
		slideshow: {
			icon: IconAgentero,
			color: '#2DBC90'
		}
	},
	[Carrier.Safeco]: {
		type: CarrierType.NonAgenteroCarrier,
		carrier: Carrier.Safeco,
		carrierProto: CarrierProto.SAFECO,
		name: 'safeco',
		isEnabled: () => true,
		logo: 'safeco-logo.png',
		raterCarrier: {
			icon: IconSafeco
		},
		label: 'Safeco',
		slideshow: {
			icon: IconSafeco,
			color: '#1A1446'
		}
	},
	[Carrier.Progressive]: {
		type: CarrierType.NonAgenteroCarrier,
		carrier: Carrier.Progressive,
		carrierProto: CarrierProto.PROGRESSIVE,
		name: 'progressive',
		isEnabled: () => true,
		logo: 'progressive-logo.png',
		raterCarrier: {
			icon: IconProgressive
		},
		label: 'Progressive',
		slideshow: {
			icon: IconProgressive,
			color: '#2D95E5'
		}
	},
	[Carrier.Lamar]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Lamar,
		carrierProto: CarrierProto.LAMAR,
		name: 'lamar',
		url: 'lamar',
		landingUrl: 'lamar',
		isEnabled: () => true,
		logo: 'lamar-logo.png',
		raterCarrier: {
			icon: IconLamar
		},
		label: 'Lamar',
		slideshow: {
			icon: IconLamarSlideshow,
			color: '#000D47'
		}
	},
	[Carrier.Biberk]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Biberk,
		carrierProto: CarrierProto.BIBERK,
		name: 'biberk',
		url: 'biberk',
		landingUrl: 'biberk',
		isEnabled: () => true,
		logo: 'biberk-logo.svg',
		raterCarrier: {
			icon: IconBiBerk
		},
		label: 'Biberk',
		slideshow: {
			icon: IconBambooSlideshow,
			color: '#3499CC'
		}
	},
	[Carrier.Nationwide]: {
		type: CarrierType.NonAgenteroCarrier,
		carrier: Carrier.Nationwide,
		carrierProto: CarrierProto.NATIONWIDE,
		name: 'nationwide',
		isEnabled: () => true,
		logo: 'nationwide-logo.png',
		raterCarrier: {
			icon: IconNationwide
		},
		label: 'Nationwide',
		slideshow: {
			icon: IconNationwide,
			color: '#0047BB'
		}
	},
	[Carrier.Pathpoint]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Pathpoint,
		carrierProto: CarrierProto.PATHPOINT,
		name: 'pathpoint',
		url: 'pathpoint',
		landingUrl: 'pathpoint',
		isEnabled: () => true,
		logo: 'pathpoint-logo.svg',
		raterCarrier: RaterCarrierEnum.NonRaterCarrier,
		label: 'Pathpoint',
		slideshow: {
			icon: IconAgentero,
			color: '#2DBC90'
		}
	},
	[Carrier.Hiscox]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Hiscox,
		carrierProto: CarrierProto.HISCOX,
		name: 'hiscox',
		url: 'hiscox',
		landingUrl: 'hiscox',
		isEnabled: () => true,
		logo: 'hiscox-logo.svg',
		raterCarrier: RaterCarrierEnum.NonRaterCarrier,
		label: 'Hiscox',
		slideshow: {
			icon: HiscoxSlideshow,
			color: '#EE2D24'
		}
	},
	[Carrier.Amtrust]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Amtrust,
		carrierProto: CarrierProto.AMTRUST,
		name: 'amtrust',
		url: 'amtrust',
		landingUrl: 'amtrust',
		isEnabled: () => true,
		logo: 'amtrust-logo.png',
		raterCarrier: {
			icon: IconAmtrust
		},
		label: 'Amtrust',
		slideshow: {
			icon: IconAmtrust,
			color: '#002D72'
		}
	},
	[Carrier.GreatAmerican]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.GreatAmerican,
		carrierProto: CarrierProto.GAIG,
		name: 'great_american',
		url: 'gaig',
		landingUrl: 'gaig',
		isEnabled: () => true,
		logo: 'great-american-logo.png',
		raterCarrier: {
			icon: IconGreatAmerican
		},
		label: 'Great American',
		slideshow: {
			icon: IconGreatAmerican,
			color: '#003366'
		}
	},
	[Carrier.AMIG]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.AMIG,
		carrierProto: CarrierProto.AMIG,
		name: 'amig',
		url: 'amig',
		landingUrl: 'amig',
		isEnabled: () => process.env.NEXT_PUBLIC_AMIG === 'true',
		logo: 'amig-logo.png',
		raterCarrier: {
			icon: IconAMIG
		},
		label: 'American Modern',
		slideshow: {
			icon: IconAMIG,
			color: '#4A4194'
		}
	},

	[Carrier.Foremost]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Foremost,
		carrierProto: CarrierProto.FOREMOST,
		name: 'foremost',
		url: 'foremost',
		landingUrl: 'foremost',
		isEnabled: () => process.env.NEXT_PUBLIC_FOREMOST === 'true',
		logo: 'foremost-logo.png',
		raterCarrier: RaterCarrierEnum.NonRaterCarrier,
		label: 'Foremost',
		slideshow: {
			icon: IconAgentero,
			color: '#2DBC90'
		}
	},
	[Carrier.BristolWest]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.BristolWest,
		carrierProto: CarrierProto.BRISTOL_WEST,
		name: 'bristolWest',
		url: 'bristol-west',
		landingUrl: 'bristolwest',
		isEnabled: () => process.env.NEXT_PUBLIC_BRISTOL_WEST === 'true',
		logo: 'bristol-logo.svg',
		raterCarrier: {
			icon: IconBristolWest
		},
		label: 'Bristol West',
		slideshow: {
			icon: IconBristolWest,
			color: '#2559A4'
		}
	},
	[Carrier.Westfield]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Westfield,
		carrierProto: CarrierProto.WESTFIELD,
		name: 'westfield',
		url: 'westfield',
		landingUrl: 'westfield',
		isEnabled: () => process.env.NEXT_PUBLIC_WESTFIELD === 'true',
		logo: 'westfield-logo.png',
		raterCarrier: {
			icon: IconWestfield
		},
		label: 'Westfield',
		slideshow: {
			icon: IconWestfield,
			color: '#F05136'
		}
	},
	[Carrier.Lonestar]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Lonestar,
		carrierProto: CarrierProto.LONESTAR,
		name: 'lonestar',
		url: 'lonestar',
		landingUrl: 'lonestar',
		isEnabled: () => process.env.NEXT_PUBLIC_LONESTAR === 'true',
		logo: 'lonestar-logo.png',
		raterCarrier: {
			icon: IconLonestar
		},
		label: 'Lonestar',
		slideshow: {
			icon: IconLonestar,
			color: '#DE2E26'
		}
	},
	[Carrier.Dairyland]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Dairyland,
		url: 'dairyland',
		landingUrl: 'dairyland',
		carrierProto: CarrierProto.DAIRYLAND,
		name: 'dairyland',
		isEnabled: () => process.env.NEXT_PUBLIC_DAIRYLAND === 'true',
		logo: 'dairyland-logo.svg',
		raterCarrier: {
			icon: IconDairyland
		},
		label: 'Dairyland',
		slideshow: {
			icon: IconDairyland,
			color: '#5A319B'
		}
	},
	[Carrier.Delos]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Delos,
		carrierProto: CarrierProto.DELOS,
		name: 'delos',
		url: 'delos',
		landingUrl: 'delos',
		isEnabled: () => process.env.NEXT_PUBLIC_DELOS === 'true',
		logo: 'delos-logo.png',
		raterCarrier: {
			icon: IconDelos
		},
		label: 'Delos',
		slideshow: {
			icon: IconDelos,
			color: '#006CFF'
		}
	},
	[Carrier.TravelersCommercial]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.TravelersCommercial,
		carrierProto: CarrierProto.TRAVELERS_COMMERCIAL,
		name: 'travelers-commercial',
		isEnabled: () => process.env.NEXT_PUBLIC_TRAVELERS_COMMERCIAL === 'true',
		logo: 'travelers-logo.png',
		landingUrl: 'travelers',
		url: 'travelers',
		raterCarrier: {
			icon: IconTravelers
		},
		label: 'Travelers',
		slideshow: {
			icon: IconTravelers,
			color: '#E01719'
		}
	},
	[Carrier.Employers]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Employers,
		carrierProto: CarrierProto.EMPLOYERS,
		name: 'employers',
		isEnabled: () => process.env.NEXT_PUBLIC_EMPLOYERS === 'true',
		logo: 'employers-logo.png',
		landingUrl: 'employers',
		url: 'employers',
		raterCarrier: {
			icon: IconEmployers
		},
		label: 'Employers',
		slideshow: {
			icon: IconEmployers,
			color: '#1E427D'
		}
	},
	[Carrier.Steadily]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Steadily,
		carrierProto: CarrierProto.STEADILY,
		name: 'steadily',
		isEnabled: () => process.env.NEXT_PUBLIC_STEADILY === 'true',
		logo: 'steadily-logo.png',
		landingUrl: 'steadily',
		url: 'steadily',
		raterCarrier: RaterCarrierEnum.NonRaterCarrier,
		label: 'Steadily',
		slideshow: {
			icon: IconAgentero,
			color: '#2DBC90'
		}
	},
	[Carrier.AssuranceAmerica]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.AssuranceAmerica,
		carrierProto: CarrierProto.ASSURANCE_AMERICA,
		name: 'assuranceamerica',
		isEnabled: () => process.env.NEXT_PUBLIC_ASSURANCE_AMERICA === 'true',
		logo: 'assurance-america-logo.png',
		landingUrl: 'assuranceamerica',
		url: 'assuranceamerica',
		raterCarrier: {
			icon: IconAssuranceAmerica
		},
		label: 'Assurance America',
		slideshow: {
			icon: IconAssuranceAmerica,
			color: '#1C6CB9'
		}
	},
	[Carrier.Commonwealth]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Commonwealth,
		carrierProto: CarrierProto.COMMONWEALTH,
		name: 'commonwealth',
		isEnabled: () => process.env.NEXT_PUBLIC_COMMONWEALTH === 'true',
		logo: 'commonwealth.png',
		landingUrl: 'commonwealth',
		url: 'commonwealth',
		raterCarrier: {
			icon: IconCommonwealth
		},
		label: 'Commonwealth',
		slideshow: {
			icon: IconCommonwealth,
			color: '#006CFD'
		}
	},
	[Carrier.Wellington]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Wellington,
		carrierProto: CarrierProto.WELLINGTON,
		name: 'wellington',
		url: 'wellington',
		landingUrl: 'wellington',
		isEnabled: () => process.env.NEXT_PUBLIC_WELLINGTON === 'true',
		logo: 'wellington-logo.png',
		raterCarrier: RaterCarrierEnum.NonRaterCarrier,
		label: 'Wellington',
		slideshow: {
			icon: IconAgentero,
			color: '#2DBC90'
		}
	},
	[Carrier.Lio]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Lio,
		carrierProto: CarrierProto.LIO,
		name: 'lio',
		url: 'lio',
		landingUrl: 'lio',
		isEnabled: () => process.env.NEXT_PUBLIC_LIO === 'true',
		logo: 'lio-logo.png',
		raterCarrier: {
			icon: IconLio
		},
		label: 'Lio',
		slideshow: {
			icon: IconLio,
			color: '#1C2D40'
		}
	},
	[Carrier.Swyfft]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Swyfft,
		carrierProto: CarrierProto.SWYFFT,
		name: 'swyfft',
		url: 'swyfft',
		landingUrl: 'swyfft',
		isEnabled: () => process.env.NEXT_PUBLIC_SWYFFT === 'true',
		logo: 'swyfft-logo.png',
		raterCarrier: RaterCarrierEnum.NonRaterCarrier,
		label: 'Swyfft',
		slideshow: {
			icon: IconAgentero,
			color: '#2DBC90'
		}
	},
	[Carrier.Neptune]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Neptune,
		carrierProto: CarrierProto.NEPTUNE,
		name: 'neptune',
		url: 'neptune',
		landingUrl: 'neptune',
		isEnabled: () => process.env.NEXT_PUBLIC_NEPTUNE === 'true',
		logo: 'neptune-logo.png',
		raterCarrier: RaterCarrierEnum.NonRaterCarrier,
		label: 'Neptune',
		slideshow: {
			icon: IconAgentero,
			color: '#2DBC90'
		}
	},
	[Carrier.Orion180]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Orion180,
		carrierProto: CarrierProto.ORION180,
		name: 'orion180',
		url: 'orion180',
		landingUrl: 'orion180',
		isEnabled: () => process.env.NEXT_PUBLIC_ORION180 === 'true',
		logo: 'orion180-logo.png',
		raterCarrier: RaterCarrierEnum.NonRaterCarrier,
		label: 'Orion180',
		slideshow: {
			icon: IconAgentero,
			color: '#2DBC90'
		}
	},
	[Carrier.Stonegate]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Stonegate,
		carrierProto: CarrierProto.STONEGATE,
		name: 'stonegate',
		url: 'stonegate',
		landingUrl: 'stonegate',
		isEnabled: () => process.env.NEXT_PUBLIC_STONEGATE === 'true',
		logo: 'stonegate-logo.png',
		raterCarrier: {
			icon: IconStonegate
		},
		label: 'Stonegate',
		slideshow: {
			icon: IconStonegate,
			color: '#1F2021'
		}
	},
	[Carrier.Unique]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Unique,
		carrierProto: CarrierProto.UNIQUE,
		name: 'unique',
		url: 'unique',
		landingUrl: 'unique',
		isEnabled: () => process.env.NEXT_PUBLIC_UNIQUE === 'true',
		logo: 'unique-logo.png',
		raterCarrier: {
			icon: IconUnique
		},
		label: 'Unique',
		slideshow: {
			icon: IconUnique,
			color: '#0B1C23'
		}
	},
	[Carrier.TheGeneral]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.TheGeneral,
		carrierProto: CarrierProto.THE_GENERAL,
		name: 'thegeneral',
		url: 'thegeneral',
		landingUrl: 'thegeneral',
		isEnabled: () => process.env.NEXT_PUBLIC_THE_GENERAL === 'true',
		logo: 'thegeneral-logo.png',
		raterCarrier: {
			icon: IconTheGeneral
		},
		label: 'The General',
		slideshow: {
			icon: IconTheGeneral,
			color: '#007A53'
		}
	},
	[Carrier.ForemostSignature]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.ForemostSignature,
		carrierProto: CarrierProto.FOREMOST_SIGNATURE,
		name: 'foremostsignature',
		url: 'foremostsignature',
		landingUrl: 'foremostsignature',
		isEnabled: () => process.env.NEXT_PUBLIC_FOREMOST_SIGNATURE === 'true',
		logo: 'foremostsignature-logo.png',
		raterCarrier: {
			icon: IconForemostSignature
		},
		label: 'Foremost Signature',
		slideshow: {
			icon: IconForemostSignature,
			color: '#67B1E2'
		}
	},
	[Carrier.Rainbow]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Rainbow,
		carrierProto: CarrierProto.RAINBOW,
		name: 'rainbow',
		url: 'rainbow',
		landingUrl: 'rainbow',
		isEnabled: () => process.env.NEXT_PUBLIC_RAINBOW === 'true',
		logo: 'rainbow-logo.png',
		raterCarrier: {
			icon: IconRainbow
		},
		label: 'Rainbow',
		slideshow: {
			icon: IconRainbow,
			color: '#213368'
		}
	},
	[Carrier.Aspire]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Aspire,
		carrierProto: CarrierProto.ASPIRE,
		name: 'aspire',
		url: 'aspire',
		landingUrl: 'aspire',
		isEnabled: () => process.env.NEXT_PUBLIC_ASPIRE === 'true',
		logo: 'aspire-logo.png',
		raterCarrier: {
			icon: IconAspire
		},
		label: 'Aspire',
		slideshow: {
			icon: IconAspire,
			color: '#007DB6'
		}
	},
	[Carrier.Chubb]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Chubb,
		carrierProto: CarrierProto.CHUBB,
		name: 'chubb',
		url: 'chubb',
		landingUrl: 'chubb',
		isEnabled: () => process.env.NEXT_PUBLIC_CHUBB === 'true',
		logo: 'chubb-logo.png',
		raterCarrier: {
			icon: IconChubb
		},
		label: 'Chubb',
		slideshow: {
			icon: IconChubb,
			color: '#000'
		}
	},
	[Carrier.ChubbPersonal]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.ChubbPersonal,
		carrierProto: CarrierProto.CHUBB_PERSONAL,
		name: 'chubb_personal',
		url: 'chubb_personal',
		landingUrl: 'chubb',
		isEnabled: () => process.env.NEXT_PUBLIC_CHUBB === 'true',
		logo: 'chubb-personal-logo.png',
		raterCarrier: {
			icon: IconChubb
		},
		label: 'Chubb',
		slideshow: {
			icon: IconChubb,
			color: '#000'
		}
	},
	[Carrier.Hanover]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Hanover,
		carrierProto: CarrierProto.HANOVER,
		name: 'hanover',
		url: 'hanover',
		landingUrl: 'hanover',
		isEnabled: () => process.env.NEXT_PUBLIC_HANOVER === 'true',
		logo: 'hanover-logo.png',
		raterCarrier: {
			icon: IconHanover
		},
		label: 'Hanover',
		slideshow: {
			icon: IconHanover,
			color: '#231F20'
		}
	},
	[Carrier.Ses]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Ses,
		carrierProto: CarrierProto.SES,
		name: 'ses',
		url: 'ses',
		landingUrl: 'ses',
		isEnabled: () => process.env.NEXT_PUBLIC_SES === 'true',
		logo: 'ses-logo.png',
		raterCarrier: {
			icon: IconSes
		},
		label: 'Ses',
		slideshow: {
			icon: IconSes,
			color: '#86B5DE'
		}
	},
	[Carrier.BoundlessRider]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.BoundlessRider,
		carrierProto: CarrierProto.BOUNDLESS_RIDER,
		name: 'boundlessrider',
		url: 'boundless-rider',
		landingUrl: 'boundless-rider',
		isEnabled: () => process.env.NEXT_PUBLIC_BOUNDLESS_RIDER === 'true',
		logo: 'boundless-rider-logo.svg',
		raterCarrier: {
			icon: IconBoundlessRider
		},
		label: 'Boundless Rider',
		slideshow: {
			icon: IconBoundlessRiderSlideshow,
			color: '#FAB823'
		}
	},
	[Carrier.Root]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Root,
		carrierProto: CarrierProto.ROOT,
		name: 'root',
		url: 'root',
		landingUrl: 'root',
		isEnabled: () => process.env.NEXT_PUBLIC_ROOT === 'true',
		logo: 'root-logo.png',
		raterCarrier: {
			icon: IconRoot
		},
		label: 'Root',
		slideshow: {
			icon: IconRootSlideshow,
			color: '#FF5715'
		}
	},
	[Carrier.Kemper]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Kemper,
		carrierProto: CarrierProto.KEMPER,
		name: 'kemper',
		url: 'kemper',
		landingUrl: 'kemper',
		isEnabled: () => true,
		logo: 'kemper-logo.svg',
		raterCarrier: {
			icon: IconKemper
		},
		label: 'Kemper',
		slideshow: {
			icon: KemperSlideshow,
			color: '#003057'
		}
	},
	[Carrier.KemperCommercial]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.KemperCommercial,
		carrierProto: CarrierProto.KEMPER_COMMERCIAL,
		name: 'kemper_commercial',
		url: 'kemper_commercial',
		landingUrl: 'kemper',
		isEnabled: () => true,
		logo: 'kemper-commercial-logo.svg',
		raterCarrier: RaterCarrierEnum.NonRaterCarrier,
		label: 'Kemper',
		slideshow: {
			icon: KemperSlideshow,
			color: '#003057'
		}
	},
	[Carrier.Three]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Three,
		carrierProto: CarrierProto.THREE,
		name: 'three',
		url: 'three',
		landingUrl: 'three',
		isEnabled: () => true,
		logo: 'three-logo.svg',
		raterCarrier: RaterCarrierEnum.NonRaterCarrier,
		label: 'Three',
		slideshow: {
			icon: ThreeSlideshow,
			color: '#C15109'
		}
	},
	[Carrier.Lightspeed]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.Lightspeed,
		carrierProto: CarrierProto.LIGHTSPEED,
		name: 'lightspeed',
		url: 'lightspeed',
		landingUrl: 'lightspeed',
		isEnabled: () => true,
		logo: 'lightspeed-logo.svg',
		raterCarrier: RaterCarrierEnum.NonRaterCarrier,
		label: 'Lightspeed',
		slideshow: {
			icon: LightspeedSlideshow,
			color: '#163561'
		}
	},
	[Carrier.MGT]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.MGT,
		carrierProto: CarrierProto.MGT,
		name: 'mgt',
		url: 'mgt',
		landingUrl: 'mgt',
		isEnabled: () => true,
		logo: 'mgt-logo.svg',
		raterCarrier: RaterCarrierEnum.NonRaterCarrier,
		label: 'MGT',
		slideshow: {
			icon: MGTSlideshow,
			color: '#2B3C72'
		}
	},
	[Carrier.PlymouthRock]: {
		type: CarrierType.AgenteroCarrier,
		carrier: Carrier.PlymouthRock,
		carrierProto: CarrierProto.PLYMOUTH_ROCK,
		name: 'plymouth-rock',
		url: 'plymouth-rock',
		landingUrl: 'plymouth-rock',
		isEnabled: () => true,
		logo: 'plymouth-rock-logo.svg',
		raterCarrier: RaterCarrierEnum.NonRaterCarrier,
		label: 'Plymouth Rock',
		slideshow: {
			icon: PlymouthRockSlideshow,
			color: '#0091D1'
		}
	}
};

export const getCarrierLogoSrc = (carrier: Carrier) => {
	const { logo } = carrierConfigurations[carrier];
	return `/portal-ui-content/assets/images/carriers/logos/${logo}`;
};

export const isCarrierValid = (carrierStrValue: string) => {
	const carrierValue = Object.values(carrierConfigurations).find(
		config => config.carrier === carrierStrValue
	);

	return !!carrierValue;
};

export const getCarrierFromCarrierValue = (carrierStrValue: string) => {
	const carrierValue = Object.values(carrierConfigurations).find(
		config => config.carrier === carrierStrValue
	);

	if (!carrierValue) {
		throw Error('This url key not exists in carriers');
	}

	return carrierValue.carrier;
};

export const getCarrierLabel = (carrier: Carrier) => {
	return carrierConfigurations[carrier].label;
};

export const getCarrierSelectValues = () => {
	return Object.values(carrierConfigurations)
		.filter(value => value.carrier !== Carrier.Undefined)
		.sort((a, b) => a.name.localeCompare(b.name))
		.map(value => ({
			value: value.carrier,
			label: value.label
		}));
};

export const getCarrierUrlKey = (carrier: Carrier) => {
	const configuration = carrierConfigurations[carrier];

	if (configuration.type !== CarrierType.AgenteroCarrier) {
		throw Error('A non-Agentero Carrier has no logo');
	}

	return configuration.url;
};

export const getCarrierFromUrl = (urlKey: string) => {
	const carrierValue = Object.values(carrierConfigurations).find(
		config => (config as AgenteroCarrier).url === urlKey
	);

	if (!carrierValue) {
		throw Error('This url key not exists in carriers');
	}

	return carrierValue.carrier;
};

export const getLandingUrlFromCarrier = (carrier: Carrier) => {
	const configuration = carrierConfigurations[carrier];

	if (configuration.type !== CarrierType.AgenteroCarrier) {
		throw Error('A non-Agentero Carrier has no landing url');
	}

	return configuration.landingUrl;
};

export const getCarrierFromLandingUrl = (landingUrl: string) => {
	const carrierValue = Object.values(carrierConfigurations).find(
		value => (value as AgenteroCarrier).landingUrl === landingUrl
	);

	if (!carrierValue) {
		throw Error(`The carrier with ${landingUrl} as landing url doesn't exist.`);
	}

	return carrierValue.carrier;
};

export const getCarrierProtoFromCarrier = (carrier: Carrier) =>
	carrierConfigurations[carrier].carrierProto;

export const getCarrierFromProto = (carrierProto: CarrierProto) => {
	const carrierValue = Object.values(carrierConfigurations).find(
		value => value.carrierProto === carrierProto
	);

	if (!carrierValue) {
		throw Error(
			`This carrier does not exist. This is the number of carrier proto: ${carrierProto}`
		);
	}

	return carrierValue.carrier;
};

export const getRaterCarriers = () => {
	return Object.values(carrierConfigurations)
		.filter(value => value.raterCarrier !== RaterCarrierEnum.NonRaterCarrier)
		.map(value => value.carrier);
};

export const getAgenteroCarriers = (): AgenteroCarrier[] =>
	Object.values(carrierConfigurations)
		.filter(value => value.type === CarrierType.AgenteroCarrier && value.isEnabled())
		.sort((a, b) => a.name.localeCompare(b.name)) as unknown as AgenteroCarrier[];

export const getAppetiteCarriers = (): AgenteroCarrier[] =>
	getAgenteroCarriers().filter(({ removeFromAppetite }) => !removeFromAppetite);

export type CarrierOption = {
	value: Carrier;
	label: string;
};

export const getAppointedCarrierFilterOptions = (): CarrierOption[] =>
	Object.values(carrierConfigurations)
		.filter(value => value.isEnabled() && value.type === CarrierType.AgenteroCarrier)
		.sort((configA, configB) => configA.name.localeCompare(configB.name))
		.map(value => ({
			label: getCarrierLabel(value.carrier),
			value: value.carrier
		}));

export const isAgenteroCarrier = (carrier: Carrier) => {
	const carrierValue = Object.values(carrierConfigurations).find(
		value => value.carrier === carrier
	);

	if (!carrierValue) {
		throw Error(`This carrier ${carrier} does not exist.`);
	}

	return carrierValue.type === CarrierType.AgenteroCarrier;
};

export const getSlideshowConfiguration = (carrier: Carrier) => {
	const carrierValue = Object.values(carrierConfigurations).find(
		value => value.carrier === carrier
	);

	if (!carrierValue) {
		throw Error(`This carrier ${carrier} does not exist.`);
	}

	return carrierValue.slideshow;
};

export const ITCCarriers = [
	Carrier.Travelers,
	Carrier.Safeco,
	Carrier.Progressive,
	Carrier.Nationwide
];

export const SemseeCarriers = [Carrier.Coterie];
